import { UserGroup } from "configs/constants";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectAuthentication, selectGroup } from "../store/authSlice";

export function withAuthUser(WrappedComponent) {
  const ComponentWithReturn = (props) => {
    const authentication = useSelector(selectAuthentication);
    const group = useSelector(selectGroup);
    if (authentication && group === UserGroup.CLIENT) {
      return <Redirect to={"/dashboard"} />;
    }
    return <WrappedComponent {...props} />;
  };
  return ComponentWithReturn;
}

export function withAuthAdmin(WrappedComponent) {
  const ComponentWithReturn = (props) => {
    const authentication = useSelector(selectAuthentication);
    const group = useSelector(selectGroup);
    if (authentication && [UserGroup.FLEXIO, UserGroup.TERRITORY].includes(group)) {
      return <Redirect to={"/admin"} />;
    }
    return <WrappedComponent {...props} />;
  };
  return ComponentWithReturn;
}
