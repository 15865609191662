import { Button, Form, Input, Radio, Select, Checkbox } from "antd";
import { isMobile } from "react-device-detect";
import _find from "lodash/find";
import _flattenDeep from "lodash/flattenDeep";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";

import { handleCreateUser } from "../store/thunk";
import { withAuthUser } from "../hocs/withAuth";
import "./RegisterPage.scss";
import { loadAllCountries } from "helper/util";
import { hubspotTrackingIdentify } from "helper/util";

import { notification } from "antd";
import { duration } from "moment";

import {
  NAME_REGEX,
  COMPANY_NAME_REGEX,
  MARKET_OPTIONS,
  INDUSTRY_OPTIONS,
  PHONE_VALIDATION
} from "configs/constants";

const RegisterPage = () => {
  const history = useHistory();
  const isLoading = useSelector((state) => state.global.isLoading);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [agreedToNewsletter, setAgreedToNewsletter] = useState(false);

  const onSubmit = async (values) => {
    if (!agreedToNewsletter) {
      // Don't submit unless I hit the newsletter Checkbox.
      return;
    }
    try {
      const { error } = await dispatch(
        handleCreateUser({ ...values /* , address, lng: lngLat[0], lat: lngLat[1] */ })
      );
      console.log(error);
      if (error) {
        const genericErrorMessage =
          '<a href="https://support.goflexio.com/content/client-registration#common-errors-when-registering">See our Support article for more details.</a>';
        console.error(
          'Registration issue. <a href="https://support.goflexio.com/content/client-registration#common-errors-when-registering">See our Support article for more details.</a>'
        );
        notification.open({
          message: "Hmm 🤔 ... Something doesn't look right.",
          description: <div dangerouslySetInnerHTML={{ __html: genericErrorMessage }} />,
          duration: 5
        });
      }
      if (!error) {
        hubspotTrackingIdentify(values);
        history.push("/welcome");
      }
    } catch {
      return false;
    }
  };

  return (
    <>
      <div className="register-page">
        <div
          className="image-logo"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/pages/user/bg-register.png)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <img src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`} alt="flexio logo" />
        </div>
        <div className="flexio-logo">
          <img
            src={`/logos/${window.REACT_APP_LOGO_FILE_NAME}`}
            alt="flexio-logo"
            id="flexio-logo"
          />
        </div>
        <div className="form">
          <Form
            scrollToFirstError={true}
            layout="vertical"
            hideRequiredMark
            colon={false}
            onFinish={onSubmit}
            form={form}
          >
            <div className="form-header">
              <div>
                <p className="title">Create an account</p>
                <p>Sign up in seconds. No credit card required.</p>
              </div>
            </div>
            <div className="form-content">
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  { type: "email", message: "Invalid Email" },
                  { required: true, message: "Required" }
                ]}
              >
                <Input size="large" placeholder="Email Address" />
              </Form.Item>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: NAME_REGEX,
                    message: "Invalid First Name"
                  }
                ]}
              >
                <Input size="large" placeholder="First Name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: NAME_REGEX,
                    message: "Invalid Last Name"
                  }
                ]}
              >
                <Input size="large" placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Required",
                    pattern: PHONE_VALIDATION
                  }
                ]}
              >
                <PhoneInput country="us" enableSearch />
              </Form.Item>
              <Form.Item
                label="Company Name"
                name="clientName"
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: COMPANY_NAME_REGEX,
                    message: "Invalid Company Name"
                  }
                ]}
              >
                <Input size="large" placeholder="Company Name" />
              </Form.Item>
              <Form.Item
                label="Market"
                name="market"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select size="large" placeholder="Please Select">
                  {MARKET_OPTIONS.map((market) => (
                    <Select.Option key={market} value={market}>
                      {market}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Industry" name="industry">
                <Select
                  size="large"
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "").includes(input)
                  }
                >
                  <Select.Option value="">Please Select</Select.Option>
                  {INDUSTRY_OPTIONS.map((industry) => (
                    <Select.Option key={industry} value={industry}>
                      {industry}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  size="large"
                  placeholder="Please Select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes((input ?? "").toLowerCase())
                  }
                >
                  {window.REACT_APP_PREFERRED_COUNTRY_LIST.map((isoCode) => {
                    const country = loadAllCountries().find((c) => c.isoCode === isoCode);
                    return country ? (
                      <Select.Option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </Select.Option>
                    ) : null;
                  })}
                  <Select.Option disabled value="">
                    ---
                  </Select.Option>
                  {loadAllCountries()
                    .filter((country) => {
                      const excludedCountries = window.REACT_APP_PREFERRED_COUNTRY_LIST;
                      return !excludedCountries.includes(country.isoCode);
                    })
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((country) => (
                      <Select.Option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Required" },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters long"
                  },
                  {
                    validator: (_, value) => {
                      const lowercaseRegex = /[a-z]/;
                      const uppercaseRegex = /[A-Z]/;
                      const specialCharRegex = /[!@#$%^&*]/;
                      const numberRegex = /[0-9]/;

                      const hasLowercase = lowercaseRegex.test(value);
                      const hasUppercase = uppercaseRegex.test(value);
                      const hasSpecialCharOrNumber =
                        specialCharRegex.test(value) || numberRegex.test(value);

                      if (hasLowercase && hasUppercase && hasSpecialCharOrNumber) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        "Password must contain a combination of upper and lowercase characters, and numbers or special characters"
                      );
                    }
                  }
                ]}
                extra="Tip: Use a combination of upper and lowercase characters, numbers, and special characters"
              >
                <Input.Password size="large" placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  { required: true, message: "Please agree to the terms and conditions" }
                ]}
              >
                <Checkbox onChange={(e) => setAgreedToNewsletter(e.target.checked)}>
                  <div className="terms-conditions">
                    By signing up, I agree with
                    <Link
                      to={{
                        pathname: isMobile
                          ? "https://www.goflexio.com/driverterms_eng"
                          : "https://www.goflexio.com/clientterms"
                      }}
                      target="_blank"
                    >
                      Flexio Terms & Conditions
                    </Link>
                  </div>
                </Checkbox>
              </Form.Item>
            </div>
            <div className="form-footer">
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                block
                size="large"
                disabled={!agreedToNewsletter}
              >
                Register
              </Button>
              <div>
                <p>Already have an account? </p>
                <Link to="/login">Login</Link>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div id="under-footer" style={{ height: "40px", display: "block" }}></div>
    </>
  );
};
export default withAuthUser(RegisterPage);
