import { configureStore } from "@reduxjs/toolkit";
import driversSlice from "modules/admin/drivers/store/slice";
import additionalVehiclesSlice from "modules/admin/drivers/store/additional-vehicle/slice";
import clientDriversSlice from "modules/client/drivers/store/slice";
import flexioServicesReducer from "modules/client/flexio-services/store/slice";
import routeHistorySlice from "modules/client/history/store/slice";
import internalDriversReducer from "modules/client/internal-drivers/store/slice";
import routeSlice from "modules/client/routes/store/slice";
import serviceSlice from "modules/client/service/store/slice";
import vehicleTypesReducer from "modules/client/vehicle-types/store/slice";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import clientsReducer from "../modules/admin/clients/store/clientSlice";
import adminRoutesReducer from "../modules/admin/orders/store/RouteSlice";
import adminLocationsReducer from "../modules/admin/orders/store/LocationSlice";
import adminOrdersReducer from "../modules/admin/orders/store/slice";
import packageTypesReducer from "../modules/admin/settings/store/PackageTypeSlice";
import ServiceReducer from "../modules/admin/settings/store/ServiceSlice";
import TerritoryReducer from "../modules/admin/settings/store/TerritorySlice";
import vehicleCategoriesReducer from "../modules/admin/settings/store/VehicleCategorySlice";
import usersReducer from "../modules/admin/users/store/userSlice";
import driverFeedbacksReducer from "../modules/admin/driver-feedback/store/slice";
import driverBroadcastsReducer from "../modules/admin/driver-broadcast/store/slice";
import authReducer from "../modules/auth/store/authSlice";
import ordersReducer from "../modules/client/active-orders/store/orderSlice";
import clientUserSlice from "../modules/client/client-users/store/slice";
import clientPackageTypesReducer from "../modules/client/package-types/store/slice";
import clientNotificationSettingsReducer from "../modules/client/settings/store/notification-settings/slice";
import clientReturnLocationsReducer from "../modules/client/settings/store/return-process/slice";
import internalDriverFeedbacksReducer from "../modules/client/driver-feedback/store/slice";
import invoicingReducer from "../modules/client/invoicing/store/slice";
import adminInvoicingReducer from "../modules/admin/invoicing/store/slice";
import SaasCountriesSlice from "modules/admin/settings/store/SaasCountriesSlice";
import FailedRegistrationSlice from "modules/admin/settings/store/FailedRegistrationSlice";
import globalReducer from "./global/slice";
import poolPackagesSlice from "modules/client/pool/store/slice";

const ordersPersistConfig = {
  key: "orders",
  storage: storage,
  whitelist: ["newOrder", "status"] // only persist "newOrder"
};

const reducers = combineReducers({
  global: globalReducer,
  //admin
  drivers: driversSlice,
  additionalVehicles: additionalVehiclesSlice,
  adminServices: ServiceReducer,
  adminOrders: adminOrdersReducer,
  adminRoutes: adminRoutesReducer,
  adminLocations: adminLocationsReducer,
  saasCountries: SaasCountriesSlice,
  failedRegistrationData: FailedRegistrationSlice,
  //client
  auth: authReducer,
  clients: clientsReducer,
  internalDrivers: internalDriversReducer,
  clientUsers: clientUserSlice,
  users: usersReducer,
  orders: persistReducer(ordersPersistConfig, ordersReducer),
  flexioServices: flexioServicesReducer,
  vehicleTypes: vehicleTypesReducer,
  clientDrivers: clientDriversSlice,
  packageTypes: packageTypesReducer,
  clientPackageTypes: clientPackageTypesReducer,
  vehicleCategories: vehicleCategoriesReducer,
  territories: TerritoryReducer,
  routes: routeSlice,
  services: serviceSlice,
  routesHistory: routeHistorySlice,
  notificationSettings: clientNotificationSettingsReducer,
  returnLocations: clientReturnLocationsReducer,
  internalDriverFeedbacks: internalDriverFeedbacksReducer,
  driverFeedbacks: driverFeedbacksReducer,
  driverBroadcasts: driverBroadcastsReducer,
  invoicing: invoicingReducer,
  adminInvoicing: adminInvoicingReducer,
  packages: poolPackagesSlice
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["orders"],
  whitelist: []
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk]
});
