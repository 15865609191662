import React, { Fragment } from "react";
import AdminSpace from "routes/spaces/AdminSpace";
import ClientSpace from "routes/spaces/ClientSpace";
import AppError from "./AppError";
import AppLoading from "./AppLoading";
function App() {
  return (
    <Fragment>
      <AppError />
      <AppLoading />
      <ClientSpace />
      <AdminSpace />
    </Fragment>
  );
}

export default App;
