import { isAndroid, isIOS } from "react-device-detect";
import { Button } from "antd";
import { WaitingIcon } from "components/svgs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./DriveRegisterSuccessPage.scss";

const DriveRegisterSuccessPage = () => {
  // const [_, setTriggerIos] = useState(false);
  const history = useHistory();
  const handleBack = () => {
    if (isAndroid) {
      // eslint-disable-next-line
      window.location.href = window.REACT_APP_DOWNLOAD_ANDROID_URL;
      return;
    }
    if (isIOS) {
      window.location.href = window.REACT_APP_DOWNLOAD_IOS_URL;
      return;
    }

    history.push("/");
  };
  return (
    <div className="driver-register-success-page-web">
      <div className="background-section" />
      <div className="main-content-page">
        <div className="wrap-logo">
          <img src={`/logos/user/${window.REACT_APP_LOGO_FILE_NAME}`} />
        </div>
        <div className="d-flex flex-column wrap-body flex-items-center flex-justify-between">
          <div className="wrap-content">
            <div className="title">Thank you for signing up as Flexio Driver</div>
            <WaitingIcon />
            <div className="orange-text">Your application is under review. </div>
            <br />
            <div className="description">
              <div>Your application has been received by the Flexio Onboarding Team.</div>
              <div>
                Please check your email for the next steps to complete your registration.
              </div>
              <div>
                <span>If you have any questions please send an email to &nbsp;</span>
                <span>
                  <a href="mailto:drivers@flexiopr.com">drivers@flexiopr.com</a> .
                </span>
              </div>
            </div>
          </div>
          <div className="wrap-button mb-3">
            <Button type="primary" onClick={handleBack}>
              OK. I got this
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DriveRegisterSuccessPage;
