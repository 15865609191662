import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import {
  TOKEN_NAME,
  REFRESH_TOKEN_NAME,
  UserRole,
  UserGroup
} from "../../../configs/constants";
import { handleCreateUser, handleLogin, handleLoginAdmin } from "./thunk";
import { hubspotTrackingIdentify } from "helper/util";
import * as amplitude from "@amplitude/analytics-browser";

const cookies = new Cookies();

function parseToken(authToken) {
  const token = authToken || cookies.get(TOKEN_NAME, { path: "/" });
  let user;
  if (!token) return undefined;
  try {
    user = jwtDecode(token);
    trackAmplitudeUser(user);
    trackHubspotContact(user);
  } catch (e) {
    console.log(e);
  }
  return user;
}

function trackAmplitudeUser(user) {
  amplitude.setUserId(user?.sub);
  const identifyEvent = new amplitude.Identify();
  identifyEvent.set("email", user?.email);
  identifyEvent.set("role", user?.role);
  identifyEvent.set("group", user?.group);
  identifyEvent.set("client", user?.client?.clientName);
  identifyEvent.setOnce("has-used-frontend", true);
  amplitude.identify(identifyEvent);
}

function trackHubspotContact(contact) {
  const hsTransformData = {
    email: contact?.email,
    phone: contact?.phone,
    firstname: contact?.firstName,
    lastname: contact?.lastname,
    company: contact?.client?.clientName,
    country: contact?.country
  };
  hubspotTrackingIdentify(hsTransformData);
}

const initialState = {
  isLoading: false,
  authentication: !!cookies.get(TOKEN_NAME, { path: "/" }),
  user: parseToken(),
  group: cookies.get("group", { path: "/" }),
  role: cookies.get("role", { path: "/" })
};

function setCookiesAndState(tokens, state, defaultUserRole, defaultUserGroup) {
  const { accessToken, refreshToken } = tokens;
  const user = parseToken(accessToken);
  const userRole = user.role || defaultUserRole;
  const userGroup = user.group || defaultUserGroup;

  cookies.set(TOKEN_NAME, accessToken, { path: "/" });
  cookies.set(REFRESH_TOKEN_NAME, refreshToken, { path: "/" });
  cookies.set("group", userGroup, { path: "/" });
  cookies.set("role", userRole, { path: "/" });

  state.role = userRole;
  state.group = userGroup;
  state.user = user;
  state.authentication = true;
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      cookies.remove(TOKEN_NAME, { path: "/" });
      cookies.remove(REFRESH_TOKEN_NAME, { path: "/" });
      cookies.remove("group", { path: "/" });
      cookies.remove("role", { path: "/" });
      state.authentication = false;
      amplitude.track("logout");
      amplitude.reset();
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      setCookiesAndState(action.payload, state, UserRole.ADMIN, UserGroup.CLIENT);
      amplitude.track("login-client");
    });
    builder.addCase(handleCreateUser.fulfilled, (state, action) => {
      setCookiesAndState(action.payload, state, UserRole.ADMIN, UserGroup.CLIENT);
      amplitude.track("register");
    });
    builder.addCase(handleLoginAdmin.fulfilled, (state, action) => {
      setCookiesAndState(action.payload, state, UserRole.ADMIN, UserGroup.FLEXIO);
      amplitude.track("login-admin");
    });
  }
});

export const { logout, logoutAdmin } = authSlice.actions;

export const selectAuthentication = (state) => state.auth.authentication;
export const selectCurrentUser = (state) => state.auth.user;
export const selectRole = (state) => state.auth.role;
export const selectGroup = (state) => state.auth.group;

export default authSlice.reducer;
