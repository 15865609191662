import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Button, Modal, Table, Popconfirm, Select, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import PoolStatus from "components/items/PoolStatus";
import request from "helper/request";
import { POOL_PACKAGE_STATUSES } from "helper/status";
import _keys from "lodash/keys";
import { separateStatusUnderlineLetter } from "modules/shared/PickupDropoff/helper";
import AddPoolPackageForm from "modules/client/pool/components/AddPoolPackageForm";
import moment from "moment";

const PoolModalList = ({
  dataSource,
  refreshList,
  loading,
  setLoading,
  poolOrder,
  ...props
}) => {
  const [packageTypes, setPackageTypes] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [showAddPackageForm, setShowAddPackageForm] = useState(false);

  useEffect(() => {
    getPackageTypes();
  }, []);

  const deletePackage = useCallback(async (pkgID) => {
    if (!checkIfPackagesAvailable()) return;
    try {
      await request.delete(`/orderv2/${pkgID}`);

      await refreshList(poolOrder);
      messageApi.success("Package deleted.");
    } catch (error) {
      console.log(error);
    }
  });

  const updateOrderStatus = useCallback(async (status) => {
    if (!checkIfPackagesAvailable()) return;
    if (!status) return;
    try {
      await request.patch(`/orderv2/update-order-status`, {
        orderV2Id: poolOrder.id,
        status
      });
      await refreshList(poolOrder);
      messageApi.success("Packages Status Updated");
    } catch (error) {
      console.log(error);
    }
  });

  const getPackageTypes = useCallback(async () => {
    const packageTypesResponse = await request.get(`/package-types?isPool=true`);

    if (!packageTypesResponse.length) {
      return;
    }

    setPackageTypes(packageTypesResponse);
  }, []);

  const onChangePackageType = useCallback(async (packageTypeId, pkg) => {
    if (!checkIfPackagesAvailable()) return;
    if (!packageTypeId) return;
    setLoading(true);
    try {
      await request.patch(`/orderv2/update-package-type/`, {
        packageId: pkg.id,
        packageTypeId
      });

      messageApi.success("Package size updated.");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const addNewPackageToOrder = useCallback(async (formData) => {
    setLoading(true);
    try {
      const transformReq = {
        ...formData,
        pickupDate: moment(formData.pickupDate).toString(),
        orderV2_id: poolOrder?.id
      };

      await request.post("/orderv2/add-package", transformReq);
      await refreshList(poolOrder);
      setShowAddPackageForm(false);
      messageApi.success("Package added succesfully.");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  });

  const checkIfPackagesAvailable = () => {
    if (!dataSource.length) return false;

    return true;
  };

  const columns = useMemo(
    () => [
      {
        title: "Package ID",
        dataIndex: "id",
        render: (value) => {
          return <p>{value.slice(0, 8).toUpperCase()}</p>;
        }
      },
      {
        title: "Package Size",
        dataIndex: "packageTypeId",
        render: (value, record) => {
          return (
            <Select
              defaultValue={value}
              onChange={(id) => onChangePackageType(id, record)}
            >
              <Select.Option value="">Choose a Package</Select.Option>
              {packageTypes.map((pkgType) => (
                <Select.Option key={pkgType.id} value={pkgType.id}>
                  {pkgType.name}
                </Select.Option>
              ))}
            </Select>
          );
        }
      },
      {
        title: "Pickup Location",
        dataIndex: "pickupAddress"
      },
      {
        title: "Dropoff Location",
        dataIndex: "dropoffAddress"
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (value, record) => {
          return <PoolStatus value={value} record={record} />;
        }
      },
      {
        title: "",
        dataIndex: "id",
        render: (value, _) => {
          return (
            <Button type="link">
              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => deletePackage(value)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteFilled style={{ color: "red", fontSize: "20px" }} />
              </Popconfirm>
            </Button>
          );
        }
      }
    ],
    [packageTypes, deletePackage]
  );
  return (
    <>
      {contextHolder}
      <Modal
        {...props}
        footer={
          <Button
            type="default"
            size="large"
            style={styleComponents.addPackageButton}
            onClick={() => setShowAddPackageForm(!showAddPackageForm)}
          >
            {showAddPackageForm ? "Cancel" : "Add New Package to Order"}
          </Button>
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            paddingRight: "2em"
          }}
        >
          <h2 className="title-order">
            <span>Order: </span>
            <span style={{ color: "#3C54A5" }}>
              {poolOrder?.id?.slice(0, 8).toUpperCase()}
            </span>
          </h2>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <span style={{ fontSize: "20px", fontWeight: "600" }}>
              Order Status: &nbsp;
            </span>
            <Select defaultValue={""} onChange={updateOrderStatus}>
              <Select.Option value={""}>Choose a status</Select.Option>
              {_keys(POOL_PACKAGE_STATUSES).map((status, index) => (
                <Select.Option key={index + 1} value={status}>
                  {separateStatusUnderlineLetter(status)}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey={"id"}
        />
        {showAddPackageForm && (
          <AddPoolPackageForm
            submit={addNewPackageToOrder}
            packageTypes={packageTypes}
            poolOrder={poolOrder}
          />
        )}
      </Modal>
    </>
  );
};

const styleComponents = {
  addPackageButton: {
    color: "white",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "24px",
    background: "#43c1c2",
    borderRadius: "2px"
  }
};

export default PoolModalList;
