import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useForm } from "react-hook-form";

import { InputHook } from "components/hook-forms";
import { ClearAllIcon } from "components/svgs/ClearAllIcon";
import { SelectHook, SwitchHook } from "components/hook-forms";
import { loadAllCountries } from "helper/util";
import { selectAllTerritories } from "modules/admin/settings/store/TerritorySlice";
import { getTerritoryList } from "modules/admin/settings/store/TerritoryThunks";

import "modules/admin/clients/components/header/AdminFilter.scss";
import { VEHICLE_TYPE_OPTIONS } from "configs/constants";
import { selectAllDrivers, selectAllCities } from "../store/slice";
import { selectAllVehicleCategories } from "modules/admin/settings/store/VehicleCategorySlice";
import { getAllVehicleCategories } from "modules/admin/settings/store/VehicleCategoryThunks";
import { doGetAllCities } from "../store/thunks";

const FilterDriver = ({ defaultValues, setShowFilter, handleClickFilter, onReset }) => {
  const territories = useSelector(selectAllTerritories);
  const cities = useSelector(selectAllCities);

  const dispatch = useDispatch();
  const {
    reset,
    control,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: "onSubmit",
    defaultValues: defaultValues
  });

  const driverList = useSelector(selectAllDrivers);

  const filteredVehicleTypes = VEHICLE_TYPE_OPTIONS.map((vehicle) => ({
    name: vehicle,
    vehicleTypeOnRegistration: vehicle,
    label: vehicle
  }));

  useEffect(() => {
    dispatch(getTerritoryList({ pageSize: 1000 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(getAllVehicleCategories());
    dispatch(doGetAllCities());
  }, [dispatch]);

  const submitForm = async (values) => {
    handleClickFilter(values);
  };

  return (
    <div className="admin-filter">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="wrap-form-filter">
          <InputHook
            control={control}
            placeholder="ID"
            label="ID"
            name="id"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Email"
            label="Email"
            name="email"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="First Name"
            label="First Name"
            name="first_name"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <InputHook
            control={control}
            placeholder="Last Name"
            label="Last Name"
            name="last_name"
            showOptionsName={false}
            errors={errors}
            suffix={<SearchOutlined />}
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            placeholder="Territory"
            label="Territory"
            name="territory_id"
            valueSelect="id"
            labelSelect="name"
            options={[{ name: "All", id: "" }, ...territories]}
            required
            errors={errors}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children ?? "").toLowerCase().includes((input ?? "").toLowerCase())
            }
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            label="Country"
            name="country"
            placeholder="All"
            valueSelect="isoCode"
            labelSelect="name"
            required
            errors={errors}
            options={loadAllCountries()}
            defaultValue={[]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children ?? "").toLowerCase().includes((input ?? "").toLowerCase())
            }
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            label="City"
            name="city"
            placeholder="All"
            valueSelect="city"
            labelSelect="name"
            required
            errors={errors}
            options={cities || []}
            defaultValue={[]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children ?? "").toLowerCase().includes((input ?? "").toLowerCase())
            }
          />
          <SelectHook
            showOptionsName={false}
            control={control}
            getValues={getValues}
            label="Registered Vehicle Type"
            name="vehicle_types"
            placeholder="All"
            valueSelect="vehicleTypeOnRegistration"
            labelSelect="name"
            required
            errors={errors}
            options={filteredVehicleTypes}
            defaultValue={[]}
            showSearch={true}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children ?? "").toLowerCase().includes((input ?? "").toLowerCase())
            }
          />
          <SwitchHook
            className="switch-hook"
            control={control}
            getValues={getValues}
            label="Active Driver"
            name="active"
            showOptionsName={false}
            defaultChecked={false}
            errors={errors}
          />
        </div>
        <div className="wrap-button">
          <Button type="link" className="collapse" onClick={() => setShowFilter(false)}>
            Collapse <UpOutlined />
          </Button>
          <Button
            type="link"
            className="svg-icon clear"
            onClick={() => {
              reset();
              onReset();
            }}
          >
            Clear <ClearAllIcon />
          </Button>
          <Button type="primary" htmlType="submit">
            Apply <CheckOutlined />
          </Button>
        </div>
      </form>
    </div>
  );
};
export default FilterDriver;
