import React from "react";
const WaitingIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.24" cx="40" cy="40" r="40" fill="#FF8400" />
      <g filter="url(#filter0_d)">
        <circle cx="40" cy="40" r="32" fill="#FF8400" />
      </g>
      <path
        d="M32 26.6666L32.0133 34.6666L37.3333 40L32.0133 45.3466L32 53.3333H48V45.3333L42.6667 40L48 34.68V26.6666H32ZM45.3333 46V50.6666H34.6667V46L40 40.6666L45.3333 46Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d"
          x="4"
          y="4"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export { WaitingIcon };
