import * as yup from "yup";
import moment from "moment";
import { PHONE_VALIDATION } from "configs/constants";
export const internalDriverForm = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup
    .string()
    .required("Phone is required")
    .matches(PHONE_VALIDATION, "Phone is required"),
  country: yup.string().required("Country is required"),
  shiftStart: yup.string().required("From Time is required"),
  shiftEnd: yup
    .string()
    .required("End time cannot be empty")
    .test("is-greater", "End time should be greater", function (value) {
      const { shiftStart } = this.parent;
      return moment(value, "HH:mm").isSameOrAfter(moment(shiftStart, "HH:mm"));
    })
});

export const registerForm = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z]).{8,}$/,
      "Password must contain a combination of upper and lowercase characters, and numbers or special characters"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match")
});
