import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";

import React, { useEffect, useState } from "react";

import { selectAllClients } from "../../../clients/store/clientSlice";
import { getClientList } from "../../../clients/store/thunks";
import { getDriverList } from "modules/admin/drivers/store/thunks";
import { selectAllDrivers } from "modules/admin/drivers/store/slice";
import { fullName } from "helper/util";

import "./ClientForm.scss";
import { doCreateUser, doUpdateUser } from "../../store/thunks";
import { useHistory } from "react-router-dom";

import { selectAllServices } from "modules/admin/settings/store/ServiceSlice";
import { getServiceList } from "modules/admin/settings/store/ServiceThunks";
import { ArrowDownIcon } from "components/svgs";
import { INTERNAL_DRIVER_SERVICE } from "configs/constants";
import { NAME_REGEX } from "configs/constants";

const clientUserInit = {
  type: "company"
};

const { Option } = Select;
const ClientForm = ({ clientUser, isNewForm = false }) => {
  const servicesList = useSelector(selectAllServices);
  const driversList = useSelector(selectAllDrivers);
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.global.isLoading);
  const history = useHistory();
  const [type, setType] = useState("company");
  const dispatch = useDispatch();
  const servicesOptions = servicesList
    .filter(
      (item) => item.name?.toLowerCase() !== INTERNAL_DRIVER_SERVICE && !item.isDefault
    )
    ?.map((service) => ({
      label: service.name,
      value: service.id
    }));
  const handleCanle = () => {
    history.push("/admin/users/clients");
  };

  const onSubmit = async (values) => {
    try {
      let erRs = undefined;
      if (!clientUser) {
        const { error } = await dispatch(
          doCreateUser({
            ...values,
            group: "client",
            type: undefined,
            services: values.services?.map((serviceId) => ({ id: serviceId }))
          })
        );
        erRs = error;
      } else {
        if (!values.password) delete values.password;
        const { error } = await dispatch(
          doUpdateUser({
            ...values,
            id: clientUser?.id,
            group: "client",
            type: undefined,
            driverIds: values.driverIds,
            services: values.services?.map((serviceId) => ({ id: serviceId }))
          })
        );
        erRs = error;
      }
      if (!erRs) history.push("/admin/users/clients");
    } catch {
      return false;
    }
  };

  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const clientList = useSelector(selectAllClients);
  useEffect(() => {
    dispatch(getDriverList());
    dispatch(getServiceList());

    clientList.length === 0 && dispatch(getClientList({ pageSize: 1000 }));
  }, [dispatch, clientList.length]);

  useEffect(() => {
    let dataForm = clientUserInit;
    if (clientUser) {
      dataForm = {
        ...clientUser,
        type: clientUser.client?.id ? "company" : "individual",
        clientId: clientUser.client?.id,
        phone: clientUser?.phone || "",
        driverIds: clientUser.client?.drivers?.map((driver) => driver.id),
        services: clientUser.client?.services?.map((service) => service.id)
      };
    }
    form.setFieldsValue(dataForm);
    setType(isNewForm ? "company" : "");
    // eslint-disable-next-line
  }, [clientUser, form]);

  return (
    <div className="ClientForm">
      <Form
        scrollToFirstError={true}
        layout="vertical"
        hideRequiredMark
        colon={false}
        onFinish={onSubmit}
        form={form}
      >
        <div className="form-content">
          {!clientUser && (
            <Form.Item
              name="type"
              rules={[{ required: true, message: "Required" }]}
              valuePropName="value"
            >
              <Radio.Group onChange={onChangeType}>
                <Radio.Button value="company">Company</Radio.Button>
                <Radio.Button value="individual">Individual</Radio.Button>
              </Radio.Group>
            </Form.Item>
          )}
          {(type === "company" || clientUser?.client?.clientType === "organization") && (
            <Form.Item label="Client" name="clientId">
              <Select size="large" placeholder="Please Select" disabled={!!clientUser}>
                {clientList.map((clientData) => (
                  <Select.Option key={clientData.id} value={clientData.id}>
                    {clientData.clientName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { type: "email", message: "Invalid Email" },
              { required: true, message: "Required" }
            ]}
          >
            <Input disabled={!!clientUser} size="large" placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Required" },
              {
                pattern: NAME_REGEX,
                message: "Invalid First Name"
              }
            ]}
          >
            <Input size="large" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Required" },
              {
                pattern: NAME_REGEX,
                message: "Invalid Last Name"
              }
            ]}
          >
            <Input size="large" placeholder="Last Name" />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone">
            <PhoneInput country="ca" enableSearch={true} />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select size="large" placeholder="Please Select">
              <Select.Option value="admin">Super Admin</Select.Option>
              <Select.Option value="manager">Manager</Select.Option>
              <Select.Option value="viewer">View Only</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: !clientUser, message: "Required" }]}
          >
            <Input.Password size="large" placeholder="Password" />
          </Form.Item>
          {(type === "individual" || clientUser?.client?.clientType === "individual") && (
            <>
              <Form.Item label="Drivers" name="driverIds">
                <Select
                  mode="multiple"
                  placeholder="Please Select Drivers"
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children ?? "").includes(input)
                  }
                >
                  {driversList?.map((driverList) => {
                    if (driverList.active === true)
                      return (
                        <Option key={driverList.id} value={driverList.id}>
                          {fullName(driverList)}
                        </Option>
                      );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Choose which Services will be specific values"
                placeholder="Services"
                name="services"
              >
                <Select
                  showSearch
                  suffixIcon={<ArrowDownIcon />}
                  mode="multiple"
                  allowClear
                  options={servicesOptions}
                  placeholder="Choose a service"
                />
              </Form.Item>
            </>
          )}
        </div>
        <div className="line-break"></div>
        <div className="form-footer">
          <Button loading={isLoading} onClick={handleCanle}>
            Cancel
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {clientUser ? "Update" : "Create"}
            <CheckCircleOutlined />
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default ClientForm;
