import { createAsyncThunk } from "@reduxjs/toolkit";
import { setGlobalLoading } from "../../../app/global/slice";
import { ACTION } from "../../../configs/constants";
import request from "../../../helper/request";

export const handleLogin = createAsyncThunk(
  "/user/auth/login/create",
  async ({ email, password }, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.fetch }));
    return request.post("/client/auth/login", {
      email,
      password
    });
  }
);
export const handleLoginAdmin = createAsyncThunk(
  "/admin/auth/login/create",
  async ({ email, password }, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.fetch }));
    return request.post("/admin/auth/login", {
      email,
      password
    });
  }
);

export const handleCreateUser = createAsyncThunk(
  "/user/auth/login/add",
  async (data, thunkAPI) => {
    thunkAPI.dispatch(setGlobalLoading({ isLoading: true, action: ACTION.handle }));
    return request.post("/client/auth/register", {
      ...data
    });
  }
);
