export const TOKEN_NAME = "flexio-token";
export const REFRESH_TOKEN_NAME = "flexio-rf";

export const PAGE_SIZE = 100;

export const ACTION = {
  fetch: "FETCH",
  handle: "HANDLE",
  getOne: "GETONE"
};

export const TIME_FORMAT_FRONTEND = "hh:mm A";
export const TIME_FORMAT_BACKEND = "HH:mm";
export const DATE_FORMAT_FRONTEND = "DD/MM/YYYY";
export const DATETIME_FORMAT_FRONTEND = "llll";

export const DATE_FORMAT_BACKEND = "DD-MM-YYYY";
export const DATE_FORMAT_API_BACKEND = "YYYY-MM-DD";

export const DAY_DATE_FORMAT = "ddd, MMM DD, YYYY";

export const COLORS = {
  primaryAdmin: "#1F3998",
  primary: "#111D5E",
  secondary: "#FF467B",
  third: "#666E98",
  black: "#000000",
  secondaryBackground: "#ECEDF3",
  red: "#DF1F00",
  yellow: "#FFAB00",
  white: "#FFFFFF",
  brown: "#C4C4C4",
  purple: "#651FFF",
  unscheduled: "#C4C4C4"
};

export const DEFAULT_CENTER = [-66.105721, 18.466333];

export const COLORS_DRIVERS = [
  "00C8B4",
  "23A9F6",
  "641EFD",
  "669A3D",
  "748BFF",
  "83C3FF",
  "9E69E0",
  "9FDC70",
  "C64EFF",
  "DC70C4",
  "F58585",
  "FF3535",
  "FF9365",
  "FFAB00",
  "7732EF",
  "EF32AB",
  "32EFC9",
  "95E914",
  "1470E9",
  "1CB486"
];

export const VEHICLE_TYPE_OPTIONS = ["Cars", "SUV", "Vans", "Pickup Truck", "Other"];
export const CLIENT_TYPES = [
  { name: "Individual", value: "individual" }, // DEPRECATED, no new clients should be individual type.
  { name: "Organization", value: "organization" }
];
export const USER_ROLES = [
  { name: "Super Admin", value: "admin" },
  { name: "Manager", value: "manager" },
  { name: "View Only", value: "viewer" }
];

export const MARKET_OPTIONS = ["B2B", "B2C", "P2P"];
export const INDUSTRY_OPTIONS = [
  "Agriculture, Forestry, Fishing and Hunting",
  "Mining, Quarrying, and Oil and Gas Extraction",
  "Utilities",
  "Construction",
  "Manufacturing",
  "Wholesale Trade",
  "Retail Trade",
  "Transportation and Warehousing",
  "Information",
  "Finance and Insurance",
  "Real Estate and Rental and Leasing",
  "Professional, Scientific, and Technical Services",
  "Management of Companies and Enterprises",
  "Administrative and Support and Waste Management and Remediation Services",
  "Educational Services",
  "Health Care and Social Assistance",
  "Arts, Entertainment, and Recreation",
  "Accommodation and Food Services",
  "Other Services (except Public Administration)",
  "Public Administration"
];

export const INTERNAL_DRIVER_SERVICE = "internal driver";

export const UserRole = {
  ADMIN: "admin",
  MANAGER: "manager",
  VIEWER: "viewer"
};

export const UserGroup = {
  FLEXIO: "flexio",
  CLIENT: "client",
  TERRITORY: "territory"
};

export const canEditPermission = [UserRole.ADMIN, UserRole.MANAGER];
export const canCreatePermission = [UserRole.ADMIN, UserRole.MANAGER];
export const canDeletePermission = [UserRole.ADMIN, UserRole.MANAGER];

export const VEHICLE_RATE_TYPES = {
  CLIENT_RATE: "client_rate",
  DRIVER_RATE: "driver_rate"
};

export const INVOICE_TYPES = {
  CLIENT_INVOICE: "client_invoice",
  DRIVER_INVOICE: "driver_invoice"
};

export const NAME_REGEX = /^[a-zA-ZñÑÀ-ú ,.'-]+$/i;
export const COMPANY_NAME_REGEX = /^[a-zA-Z0-9ñÑÀ-ú ,.'-]+$/i;

/* 
PHONE_VALIDATION
Starts with a plus sign.
Doesn't have a zero immediately after the plus sign.
Contains at least one digit after the plus sign.
Can have digits, spaces, parentheses, and hyphens.
Must be between 14 and 22 characters long.
*/
export const PHONE_VALIDATION = /^\+(?!0)(?=.*\d)[\d ()-]{1,22}$/;

export const LOCATION_TYPES = {
  PICKUP: "pickup",
  DROPOFF: "dropoff"
};

// CONSTANT TEXT
export const UPDATE_PROFILE_TO_CONTINUE =
  "Please complete your profile information to unlock all Flexio features.";
